var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Consider the two galvanic cells shown below. Please select the correct answers for VOLTS #3 and VOLTS #4 in the dropdowns below. ")]),_c('p',{staticClass:"pl-8 mb-8"},[_c('v-img',{staticStyle:{"max-width":"493px"},attrs:{"src":_vm.imageName}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" VOLTS #3: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" VOLTS #4: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"450px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }